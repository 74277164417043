@use 'sass:map';

// ==============================
// Font size mixin
// ==============================

//  default font scale bounding
$font-lock-min: rem-calc(400);
$font-lock-max: rem-calc(map.get($grid-breakpoints, lg));

@mixin fluid-font-size($min-font-size, $max-font-size, $min-vw: $font-lock-min, $max-vw: $font-lock-max) {
  $u1: unit($min-vw);
  $u2: unit($max-vw);
  $u3: unit($font-lock-min);
  $u4: unit($font-lock-max);

  @if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
    & {
      font-size: $min-font-size;
      @media screen and (min-width: $min-vw) {
        /* stylelint-disable */
        font-size: calc(
          #{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} *
            ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)})
        );
        /* stylelint-enable */
      }
      @media screen and (min-width: $max-vw) {
        font-size: $max-font-size;
      }
    }
  }
}
