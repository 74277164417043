@use 'sass:color';

.btn-group {
  // custom button type
  .btn-light-primary,
  .btn-light-black {
    color: color-contrast($light);
    background-color: $light;
    border-color: $light;

    &:hover {
      color: color-contrast(color.adjust($light, $lightness: -7.5%));
      background-color: color.adjust($light, $lightness: -7.5%);
      border-color: color.adjust($light, $lightness: -10%);
    }

    .btn-check:checked + &,
    .btn-check:active + &,
    &:active,
    &.active,
    .show > &.dropdown-toggle {
      color: color-contrast(color.adjust($light, $lightness: -10%));
      background-color: color.adjust($light, $lightness: -10%);

      // Remove CSS gradients if they're enabled
      border-color: color.adjust($light, $lightness: -12.5%);

      &:focus {
        @if $enable-shadows {
          @include box-shadow(
            $btn-active-box-shadow,
            0 0 0 $btn-focus-width rgba(mix(color-contrast($light), $light, 15%), 0.5)
          );
        } @else {
          // Avoid using mixin so we can pass custom focus shadow properly
          box-shadow: 0 0 0 $btn-focus-width rgba(mix(color-contrast($light), $light, 15%), 0.5);
        }
      }
    }
  }

  > .btn-light-primary:not(:first-child),
  > .btn-light-black:not(:first-child) {
    margin-left: 0;
  }

  > .btn-check:checked,
  > .btn-check:focus {
    + .btn-light-primary {
      color: color-contrast($primary);
      background-color: $primary;
      border-color: $primary;
    }

    + .btn-light-black {
      color: color-contrast($black);
      background-color: $black;
      border-color: $black;
    }
  }
}
