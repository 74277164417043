$additional-container-gutter: 20px;

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  @media (min-width: #{map-get($grid-breakpoints, md)}) and (max-width: #{map-get($grid-breakpoints, xl) + ($additional-container-gutter * 2)}) {
    width: calc(100% - #{$additional-container-gutter * 2});
  }
}
