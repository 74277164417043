$utilities: map-merge(
  $utilities,
  (
    'font-size': (
      property: font-size,
      class: fs,
      values: (
        xs: $font-size-xs,
        sm: $font-size-sm,
        base: $font-size-base,
        lg: $font-size-lg,
      ),
    ),
    'font-weight': (
      property: font-weight,
      values: (
        light: $font-weight-light,
        lighter: $font-weight-lighter,
        normal: $font-weight-normal,
        medium: $font-weight-medium,
        semibold: $font-weight-semibold,
        bold: $font-weight-bold,
        bolder: $font-weight-bolder,
        heavy: $font-weight-heavy,
      ),
    ),
    'letter-spacing': (
      property: letter-spacing,
      class: ls,
      values: (
        0: 0,
        1: 0.1em,
        2: 0.2em,
        3: 0.3em,
      ),
    ),
  )
);

.cursor-pointer {
  cursor: pointer;
}

// PRINT
/* stylelint-disable */
$utilities: map-merge(
  $utilities,
  (
    'display':
      map-merge(
        map-get($utilities, 'display'),
        (
          print: true,
        )
      ),
    'flex-direction':
      map-merge(
        map-get($utilities, 'flex-direction'),
        (
          print: true,
        )
      ),
    'text-align':
      map-merge(
        map-get($utilities, 'text-align'),
        (
          print: true,
        )
      ),
    'justify-content':
      map-merge(
        map-get($utilities, 'justify-content'),
        (
          print: true,
        )
      ),
  )
);
/* stylelint-enable */
