.carousel-points {
  z-index: 2;
  width: 100%;
}

.carousel-point {
  width: 12px;
  height: 12px;
  overflow: hidden;
  text-indent: -9999px;
  background-color: $gray-200;
  border-radius: 50%;
  transition: background-color 0.2s ease-in-out;

  &.active {
    background-color: $primary;
  }
}
