.shadow-bottom-lg {
  box-shadow: $box-shadow-bottom-lg;
}

.shadow-bottom-sm {
  box-shadow: $box-shadow-bottom-sm;
}

.shadow-right-lg {
  box-shadow: $box-shadow-right-lg;
}

.shadow-bottom-xlg {
  box-shadow: $box-shadow-bottom-xlg;
}
