a {
  transition: $btn-transition;
}

.link-inherit-primary {
  color: inherit;

  &:hover {
    color: $primary;
  }
}
