@use 'sass:map';

// fluid headings

h1 {
  @include fluid-font-size($h1-font-size-sm, $h1-font-size-lg);
}

h2 {
  @include fluid-font-size($h2-font-size-sm, $h2-font-size-lg);
}

h3 {
  @include fluid-font-size($h3-font-size-sm, $h3-font-size-lg);
}

h4 {
  @include fluid-font-size($h4-font-size-sm, $h4-font-size-lg);
}

h5 {
  @include fluid-font-size($h5-font-size-sm, $h5-font-size-lg);
}

h6 {
  @include fluid-font-size($h6-font-size-sm, $h6-font-size-lg);
}

p {
  font-size: $font-size-md;
}

@each $size, $value in $display-font-sizes-sm {
  .display-#{$size} {
    @include fluid-font-size($value, map.get($display-font-sizes, $size));
  }
}
