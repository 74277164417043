@import '@angular/cdk/overlay-prebuilt.css';

.modal {
  width: calc(100vw - #{$grid-gutter-width});
  max-width: $modal-md;
  max-height: calc(100vh - #{$grid-gutter-width});
  overflow: auto;

  &.modal-xl {
    max-width: $modal-xl;
  }

  &.modal-sm {
    max-width: $modal-sm;
  }
}

.modal-backdrop {
  background: rgba($black, 0.7);
}

.cdk-overlay-container {
  z-index: $zindex-modal-backdrop;
}
