@use 'sass:color';

.btn {
  text-transform: uppercase;
  letter-spacing: 0.02em;
}

.btn-black {
  &:hover,
  &:focus {
    background-color: color.adjust($black, $lightness: 14.5%);
  }
}
